@import url("https://fonts.googleapis.com/css2?family=Manrope&family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mr+Dafoe&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mrs+Sheppards&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Monsieur+La+Doulaise&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Borel&family=Caveat:wght@500&family=Playfair+Display:ital@1&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Italianno&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  overflow-x: hidden;
}

.prevent-scroll {
  overflow: hidden;
}

#root.prevent-scroll-desktop {
  overflow-y: hidden;
}

#root {
  position: relative;
  max-height: 100vh;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #495057 !important;
  background: #e8f5ee !important;
}

/* Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  margin-block: 6px;
}

*::-webkit-scrollbar-thumb {
  background-color: #437f55;
  border-radius: 20px;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #000000de !important;
  background: unset !important;
  box-shadow: unset !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  box-shadow: unset !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
  background: #437f55 !important;
  border-color: #437f55 !important;
}

.p-multiselect-panel {
  min-width: unset !important;
  width: 262px !important;
}

.p-multiselect-panel .p-multiselect-header {
  padding: 0.75rem 0 !important;
}

.p-virtualscroller {
  height: 360px !important;
}

.p-multiselect-panel .p-multiselect-header {
  .p-checkbox {
    display: none !important;
  }
}

.p-inputtext:enabled:focus {
  box-shadow: unset !important;
  border-color: #cfd4d7 !important;
}

.p-inputtext:enabled:hover {
  border-color: unset !important;
}

p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  display: none !important;
}

.custom-toast {
  @media screen and (max-width: 768px) {
    top: 60px;
    left: 12px;
  }

  @media screen and (max-width: 480px) {
    top: 74px;
    margin-right: 24px;
    border-radius: 4px;
  }
}
